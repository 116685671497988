import React from 'react';
import {Link} from 'gatsby';
import Img from 'components/atoms/Image';

const thumbnailUrl = 'https://statics.web-nari.net/uploads/others/curriculums/';
const data = [
  {
    title: 'JavaScript',
    url: 'categories/javascript',
    description: 'React, Nodejsなどフロントエンド開発に必要な知識を学びます',
    imageUrl: thumbnailUrl + 'javascript.png',
  },
  {
    title: 'HTML/CSS',
    url: 'categories/html-css',
    description: 'Webサイト開発に必要なHTML/CSSの知識を学びます。',
    imageUrl: thumbnailUrl + 'html-css.png',
  },
  {
    title: 'Ruby',
    url: 'categories/ruby',
    description: 'サーバサイド開発に必要な知識をRubyで学びます。',
    imageUrl: thumbnailUrl + 'ruby.png',
  },
  {
    title: 'IT基礎',
    description:
      'ハードウェア・OSなどコンピュータ・ネットワークなどエンジニアに必要な基礎知識を学びます。',
    url: 'categories/it-base',
    imageUrl: thumbnailUrl + 'it-base.png',
  },
  {
    title: 'Linux',
    url: 'categories/linux',
    description: 'サーバ作業、ターミナル操作に必要なLinuxの知識を学びます。',
    imageUrl: thumbnailUrl + 'linux.png',
  },
  {
    title: 'Database',
    url: 'categories/database',
    description: 'Webアプリに不可欠なデータベースについての知識を学びます。',
    imageUrl: thumbnailUrl + 'mysql.png',
  },
  {
    title: 'Git',
    url: 'categories/git',
    description: 'チーム開発に不可欠なGitについての知識を学びます。',
    imageUrl: thumbnailUrl + 'git.png',
  },
  {
    title: 'コラム',
    description:
      'エンジニアになるために必要な知識などエンジニアについてのコラムです。',
    url: 'categories/column',
    imageUrl: thumbnailUrl + 'column.png',
  },
];

const Feature = ({amp}) => {
  return (
    <div className="row">
      {data.map(({url, title, description, imageUrl}) => {
        return (
          <div className="col s12 m4">
            <div className="post-cards hoverable">
              <div className="card-image">
                <Link to={url}>
                  <Img amp={amp} src={imageUrl} alt={title} />
                </Link>
              </div>
              <div className="card-content">
                <div className="post-detail-footer">
                  <p>{description}</p>
                </div>
                <div className="post-detail" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Feature);

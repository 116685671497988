import React from 'react';
import {graphql} from 'gatsby';
import {postsPath} from '../lib/routes';
import Layout from '../components/layouts/Default';
import Post from '../components/Post';
import Feature from '../components/templates/Feature';

export default class IndexPage extends React.PureComponent {
  render() {
    const {data} = this.props;
    const {edges: latest} = data.latest;
    const {layout} = this.props.pageContext;
    return (
      <Layout layout={layout}>
        <section className="section articles">
          <div className="section-title">
            <h2>おすすめ記事</h2>
          </div>
          <Feature />
        </section>
        <section className="section articles">
          <div className="section-title">
            <h2>最新記事</h2>
          </div>
          <div className="row">
            {latest.map(({node}) => (
              <Post key={node.id} post={node} />
            ))}
          </div>
          <div className="section-footer">
            <a className="centering" href={postsPath()}>
              >> Webなりの最新記事を見る
            </a>
          </div>
        </section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery($paths: [String]) {
    recommended: allMarkdownRemark(
      filter: {frontmatter: {slug: {in: $paths}}}
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            slug
            thumbnail
            templateKey
            categories
            createdAt(formatString: "MMM DD, YYYY")
            updatedAt(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
    latest: allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
      sort: {order: DESC, fields: [frontmatter___createdAt]}
      limit: 6
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            slug
            thumbnail
            templateKey
            categories
            createdAt(formatString: "MMM DD, YYYY")
            updatedAt(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
